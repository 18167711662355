import React, { useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';

const TradingViewChart = ({ simbolo }) => {
  const { colorMode } = useColorMode();

  useEffect(() => {
    const carregarWidgetTradingView = async (simbolo, tentativas = 3, atraso = 1000) => {
      const simboloFormatado = typeof simbolo === 'string' ? simbolo.replace('.SA', '') : '';

      try {
        if (!window.TradingView) {
          await loadScript("https://s3.tradingview.com/tv.js");
        }

        if (window.TradingView) {
          const container = document.getElementById("tradingview-widget-container");
          if (container) {
            container.innerHTML = '';
          }

          console.log("Carregando o widget do TradingView...");

          new window.TradingView.widget({
            container_id: "tradingview-widget-container",
            width: "100%",
            height: 630,
            symbol: simboloFormatado,
            interval: "D",
            timezone: "Etc/UTC",
            theme: colorMode === 'dark' ? 'dark' : 'light',
            style: "1",
            locale: "pt",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            save_image: false,
            studies: [
              "RSI@tv-basicstudies"  // Indicador publicado no TradingView com nome correto
            ]
          });

        } else {
          throw new Error("TradingView não está disponível após o carregamento do script");
        }
      } catch (erro) {
        console.error(`Erro ao carregar o widget TradingView: ${erro.message}`);
        if (tentativas > 0) {
          console.log(`Tentando carregar o widget TradingView novamente. Tentativas restantes: ${tentativas}`);
          setTimeout(() => carregarWidgetTradingView(simbolo, tentativas - 1, atraso * 2), atraso);
        } else {
          console.error("Falha ao carregar o widget TradingView após várias tentativas", erro);
        }
      }
    };

    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    if (typeof simbolo === 'string' && simbolo.trim() !== '') {
      carregarWidgetTradingView(simbolo);
    } else {
      console.error("O símbolo fornecido não é uma string válida:", simbolo);
    }
  }, [simbolo, colorMode]);

  return (
    <div style={{marginTop:"10px"}} id="tradingview-widget-container"></div>
  );
};

export default TradingViewChart;
