import React, { useState } from 'react';
import { IconButton } from "@chakra-ui/react";
import { ChevronRight, ChevronLeft } from 'lucide-react';

const ResponsiveSidebarLayout = ({ children, sidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const EXPANDED_WIDTH = 340;
  const COLLAPSED_WIDTH = 40;

  return (
    <div className="fixed inset-0 flex overflow-hidden" style={{ marginTop: '70px' }}>
      {/* Main content/Workspace area */}
      <div 
        className="flex-1"
        style={{
          marginRight: `${isCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px`,
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {children}
      </div>

      {/* Sidebar */}
      <aside 
        style={{
          position: 'fixed',
          right: 0,
          top: '70px',
          bottom: 0,
          width: `${isCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px`,
          backgroundColor: 'var(--chakra-colors-gray-800)',
          transition: 'width 0.3s ease-in-out',
          overflow: 'hidden',
          zIndex: 10,
          borderLeft: '1px solid var(--chakra-colors-gray-700)',
        }}
      >
        {/* Toggle button */}
        <IconButton
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={isCollapsed ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
          position="absolute"
          left="5px"
          top="10px"
          size="sm"
          borderRadius="full"
          colorScheme="gray"
          zIndex={20}
          aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
        />

        {/* Sidebar content container */}
        <div 
          style={{
            width: `${EXPANDED_WIDTH}px`,
            height: '100%',
            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
            overflow: 'auto',
            marginLeft: isCollapsed ? EXPANDED_WIDTH : 0,
            opacity: isCollapsed ? 0 : 1,
            visibility: isCollapsed ? 'hidden' : 'visible',
            pointerEvents: isCollapsed ? 'none' : 'auto',
          }}
        >
          {sidebar}
        </div>
      </aside>
    </div>
  );
};

export default ResponsiveSidebarLayout;