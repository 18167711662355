import React, { useMemo } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Badge, useColorModeValue } from "@chakra-ui/react";
import './OptionsGrid.css';

const OptionsGrid = ({ lastPrice, spotPrice, optionData, stockData, onBidAskClick }) => {
  // Cores baseadas no tema
  const headerBg = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const colStrike = useColorModeValue("#3D5064", "#b2f5ea");
  const textColorStrike = useColorModeValue("white", "black");
  
  // Cor para o highlight da linha do lastPrice
  const highlightBg = "rgba(51, 65, 85, 0.9)"; // Tom de cinza escuro similar à imagem

  // Separar opções CALL e PUT
  const { callOptions, putOptions } = useMemo(() => {
    const callOptions = optionData.filter(option => option.category === "CALL");
    const putOptions = optionData.filter(option => option.category === "PUT");
    return { callOptions, putOptions };
  }, [optionData]);

  // Mesclar opções CALL e PUT pelo strike
  const mergedOptions = useMemo(() => {
    return callOptions.map(call => {
      const put = putOptions.find(p => p.strike === call.strike);
      return { call, put };
    });
  }, [callOptions, putOptions]);

  // Função para obter o strike mais próximo
  const getClosestStrike = useMemo(() => {
    if (!mergedOptions || mergedOptions.length === 0) return null;
    let closestStrike = mergedOptions[0].call?.strike;
    let minDifference = Math.abs(mergedOptions[0].call?.strike - spotPrice);
    
    mergedOptions.forEach(option => {
      if (!option.call) return;
      const difference = Math.abs(option.call.strike - spotPrice);
      if (difference < minDifference) {
        closestStrike = option.call.strike;
        minDifference = difference;
      }
    });
    return closestStrike;
  }, [mergedOptions, spotPrice]);

  // Funções auxiliares
  const getDisplaySymbol = (symbol) => {
    return symbol ? symbol.replace(/^BOVAH|^BOVAW|^BOVAP/, '') : '-';
  };

  const formatNumber = (num) => {
    return (typeof num === 'number' && !isNaN(num)) ? num.toFixed(2) : '-';
  };

  const renderBadge = (value, type, side, option, onClick) => {
    if (!value) return '-';
    
    const badgeColor = side === 'bid' ? '#991B1B' : '#065F46'; // Cores personalizadas para bid e ask
    
    return (
      <Badge
        bg={badgeColor}
        color="white"
        borderRadius="md"
        p={1}
        fontSize="12px"
        onClick={() => onClick(option, type, side)}
        cursor="pointer"
      >
        {value}
      </Badge>
    );
  };

  return (
    <div className="grid-container">
      <div className="options-grid-header">
        <Table variant="simple" size="12px">
          <Thead bg={headerBg}>
            <Tr>
              <Th p={1} fontSize="12px" color={textColor}>Código</Th>
              <Th p={1} fontSize="12px" color={textColor}>Var</Th>
              <Th p={1} fontSize="12px" color={textColor}>Último</Th>
              <Th p={1} fontSize="12px" color={textColor}>Delta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Theta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Bid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Ask</Th>
              <Th p={1} fontSize="12px" color={textColor}>Strike</Th>
              <Th p={1} fontSize="12px" color={textColor}>Bid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Ask</Th>
              <Th p={1} fontSize="12px" color={textColor}>Delta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Theta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Último</Th>
              <Th p={1} fontSize="12px" color={textColor}>Var</Th>
              <Th p={1} fontSize="12px" color={textColor}>Código</Th>
            </Tr>
          </Thead>
        </Table>
      </div>
      
      <div className="options-grid-body custom-scrollbar">
        <Table variant="simple" size="12px">
          <Tbody>
            {mergedOptions.map((option, index) => {
              const isLastPrice = Math.abs(option.call?.strike - lastPrice) < 0.001;
              
              return (
<Tr 
  key={index} 
  bg={isLastPrice ? highlightBg : 'transparent'}
  className={isLastPrice ? 'current-price-row' : ''}
>
                  {/* CALL side */}
                  <Td p={1} fontSize="12px" color={textColor}>
                    {getDisplaySymbol(option.call?.symbol)}
                  </Td>
                  <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.call?.variation)}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.call?.value)}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.call?.delta)}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.call?.theta)}</Td>
                  
                  {/* CALL Bid/Ask */}
                  <Td p={1} fontSize="12px" color={textColor}>
                    {renderBadge(option.call?.bid, 'call', 'bid', option.call, onBidAskClick)}
                  </Td>
                  <Td p={1} fontSize="12px" color={textColor}>
                    {renderBadge(option.call?.ask, 'call', 'ask', option.call, onBidAskClick)}
                  </Td>
                  
                  {/* Strike Price */}
                  <Td 
                    p={1} 
                    backgroundColor={option.call?.strike === getClosestStrike ? "#A2AEBA" : colStrike} 
                    alignItems="center" 
                    color={textColorStrike} 
                    textAlign="center" 
                    fontSize="12px"
                  >
                    {formatNumber(option.call?.strike)}
                  </Td>
                  
                  {/* PUT side */}
                  {option.put ? (
                    <>
                      <Td p={1} fontSize="12px" color={textColor}>
                        {renderBadge(option.put?.bid, 'put', 'bid', option.put, onBidAskClick)}
                      </Td>
                      <Td p={1} fontSize="12px" color={textColor}>
                        {renderBadge(option.put?.ask, 'put', 'ask', option.put, onBidAskClick)}
                      </Td>
                      <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.put?.delta)}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.put?.theta)}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.put?.value)}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.put?.variation)}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{getDisplaySymbol(option.put?.symbol)}</Td>
                    </>
                  ) : (
                    <Td p={1} colSpan={7} />
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default OptionsGrid;