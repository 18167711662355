import React, { useState, useMemo } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Flex,
  Divider,
  useColorModeValue
} from "@chakra-ui/react";
import { TrendingUp, TrendingDown, Search, Plus, Pencil } from "lucide-react";

const StockTable = ({ 
  stocks = [], 
  stockData = {}, 
  onSelectStock = () => {}, 
  onOpenDrawer = () => {} 
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const hoverBg = useColorModeValue("gray.100", "gray.800");

  const validStocks = Array.isArray(stocks) ? stocks : [];

  const filteredStocks = useMemo(() => {
    return validStocks.filter(stock => {
      if (!stock || !stock.ticker) return false;
      const cleanedTicker = stock.ticker.replace(".SA", "").toLowerCase();
      return cleanedTicker.includes(searchTerm.toLowerCase());
    });
  }, [validStocks, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box w="100%" bg={bgColor} borderRadius="xl">
      {/* Header Section */}
      <Box p={3} borderBottom="1px" borderColor={borderColor}>
        <Flex justify="space-between" align="center" mb={3}>
          <Text fontSize="md" fontWeight="medium">
            Favoritos
          </Text>
          <HStack spacing={1}>
            <IconButton
              icon={<Pencil size={14} />}
              size="sm"
              variant="ghost"
              colorScheme="blue"
            />
            <IconButton
              icon={<Plus size={14} />}
              size="sm"
              variant="ghost"
              colorScheme="blue"
              onClick={onOpenDrawer}
            />
          </HStack>
        </Flex>

        <InputGroup size="sm">
          <InputLeftElement pointerEvents="none">
            <Search size={14} opacity={0.5} />
          </InputLeftElement>
          <Input
            placeholder="Buscar ticker..."
            variant="filled"
            fontSize="13px"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>
      </Box>

      {/* Stocks List */}
      <VStack spacing={0} align="stretch" divider={<Divider />}>
        {filteredStocks.length > 0 ? (
          filteredStocks.map((stock) => {
            if (!stock || !stock.ticker) return null;

            const cleanedTicker = stock.ticker?.replace(".SA", "");
            const stockInfo = stockData[cleanedTicker];
            const price = stockInfo?.price || stock.price;
            const variation = stockInfo?.variation;

            return (
              <Box
                key={stock.ticker}
                py={2}
                px={3}
                cursor="pointer"
                onClick={() => onSelectStock(stock.symbol)}
                _hover={{ bg: hoverBg }}
                transition="background 0.2s"
              >
                <Flex justify="space-between" align="center">
                  <HStack spacing={2}>
                    <Box
                      w="1.5"
                      h="1.5"
                      borderRadius="full"
                      bg={variation >= 0 ? "green.500" : "red.500"}
                    />
                    <Box>
                      <Text fontSize="13px" fontWeight="500">
                        {cleanedTicker}
                      </Text>
                      <Text fontSize="12px" color="gray.500" fontFamily="mono">
                        R$ {price?.toFixed(2) || '—'}
                      </Text>
                    </Box>
                  </HStack>

                  <HStack spacing={1}>
                    {variation !== undefined && variation !== null ? (
                      <Flex
                        align="center"
                        color={variation >= 0 ? "green.500" : "red.500"}
                      >
                        {variation >= 0 ? (
                          <TrendingUp size={14} />
                        ) : (
                          <TrendingDown size={14} />
                        )}
                        <Text ml={1} fontSize="12px" fontWeight="500">
                          {variation >= 0 ? "+" : ""}
                          {variation.toFixed(2)}%
                        </Text>
                      </Flex>
                    ) : (
                      "—"
                    )}
                  </HStack>
                </Flex>
              </Box>
            );
          })
        ) : (
          <Box py={3} textAlign="center" color="gray.500" fontSize="13px">
            {searchTerm 
              ? "Nenhum ativo encontrado para esta busca" 
              : "Nenhuma ação encontrada"
            }
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default StockTable;