import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Select,
  HStack,
  Skeleton,
  Text,
  Grid,
  Flex,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Bar } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
} from 'chart.js';
import { AlertCircle, TrendingUp, TrendingDown } from 'lucide-react';
import annotationPlugin from 'chartjs-plugin-annotation';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);


const formatYAxis = (value) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1) + 'B';
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'K';
  } else {
    return value;
  }
};


const parseBrazilianNumber = (value) => {
  if (!value || typeof value !== 'string') return 0; // Retorna 0 se o valor for inválido.
  const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
  return isNaN(parsedValue) ? 0 : parsedValue; // Verifica se a conversão resultou em NaN.
};


const formatDateToBrazilian = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string' || dateStr.length !== 10) {
    console.error('Invalid date string:', dateStr);
    return 'Data inválida';
  }

  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(5, 7);
  const day = dateStr.substring(8, 10);

  return `${day}/${month}/${year}`;
};

const AluguelChart = () => {
  const [alugueisData, setAlugueisData] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [selectedTicker, setSelectedTicker] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDateCarga, setSelectedDateCarga] = useState('');
  const [uniqueDates, setUniqueDates] = useState([]);

  // Cores dinâmicas baseadas no modo de tema
  const tableBg = useColorModeValue("gray.50", "gray.700");
  const tableTextColor = useColorModeValue("gray.800", "gray.200");
  const cardBg = useColorModeValue("#e2e8f0", "#2d3748");
  const cardTextColor = useColorModeValue("black", "white");
  const axisColor = useColorModeValue("#000000", "#ffffff"); // Nova cor para os eixos
  const gridColor = useColorModeValue("#ccc", "#4A5568"); // Nova cor para o grid
  const tableHoverColor = useColorModeValue("gray.100", "gray.700"); // Adicione esta linha


  // Otimize a função de fetch usando useCallback
  const fetchAlugueisData = useCallback(async () => {
    try {
      const response = await axios.get('https://api.fatcat.app.br/aluguel');
      const data = response.data.map(item => ({
        ...item,
        qtdeAtivo: parseBrazilianNumber(item.qtdeAtivo),
        taxaTomador: parseBrazilianNumber(item.taxaTomador),
        varPercQtdeAtivo: parseBrazilianNumber(item.varPercQtdeAtivo),
        varPercTaxaTomador: parseBrazilianNumber(item.varPercTaxaTomador),
      }));

      const uniqueTickers = [...new Set(data.map(item => item.ticker))];
      const uniqueDatesArr = [...new Set(data.map(item => item.dataCarga))]
        .sort((a, b) => new Date(b) - new Date(a));

      console.log('Datas disponíveis:', uniqueDatesArr);
      
      setAlugueisData(data);
      setTickers(uniqueTickers);
      setUniqueDates(uniqueDatesArr);
      
      // Seleciona o primeiro ticker e a data mais recente
      setSelectedTicker(uniqueTickers[0]);
      setSelectedDateCarga(uniqueDatesArr[0]);
    } catch (error) {
      console.error('Erro ao buscar dados de aluguel:', error);
    } finally {
      setLoading(false);
    }
  }, []);


  // Otimize o cálculo de estatísticas usando useMemo
  const stats = useMemo(() => {
    const selectedData = alugueisData.filter(item => item.ticker === selectedTicker);
    if (selectedData.length === 0) {
      return {
        avgLendingFee: "0.00",
        avgQuantity: "0",
        lastFee: "0.00",
        lastQuantity: "0",
        lastQtdeVar: 0,
        lastTaxaVar: 0
      };
    }

    const sum = selectedData.reduce((acc, curr) => ({
      taxaTomador: acc.taxaTomador + curr.taxaTomador,
      qtdeAtivo: acc.qtdeAtivo + curr.qtdeAtivo
    }), { taxaTomador: 0, qtdeAtivo: 0 });

    const lastData = selectedData[selectedData.length - 1];

    return {
      avgLendingFee: (sum.taxaTomador / selectedData.length).toFixed(2),
      avgQuantity: Math.round(sum.qtdeAtivo / selectedData.length).toString(),
      lastFee: lastData.taxaTomador.toFixed(2),
      lastQuantity: lastData.qtdeAtivo.toString(),
      lastQtdeVar: lastData.varPercQtdeAtivo,
      lastTaxaVar: lastData.varPercTaxaTomador
    };
  }, [alugueisData, selectedTicker]);


  // Otimização: Memoize a transformação dos dados do ticker
  const tickerData = useMemo(() => {
    const filtered = alugueisData.filter((item) => item.ticker === selectedTicker);
    // Ordenar os dados por data de forma crescente
    return filtered.sort((a, b) => new Date(a.dataCarga) - new Date(b.dataCarga));
  }, [alugueisData, selectedTicker]);


  // Otimização: Memoize as labels do gráfico com formatação correta
  const chartLabels = useMemo(() => {
    return tickerData.map((item) => {
      const date = new Date(item.dataCarga + 'T00:00:00');
      return date.toLocaleDateString('pt-BR');
    });
  }, [tickerData]);


  // Otimização: Memoize os datasets
  const chartDatasets = useMemo(() => {
    // Debug dos dados sendo usados no dataset
    console.log('Gerando datasets com dados:', tickerData);
    return [
      {
        label: 'Quantidade de Ativos',
        data: tickerData.map((item) => {
          console.log('Quantidade para data', item.dataCarga, ':', item.qtdeAtivo);
          return item.qtdeAtivo;
        }),
        backgroundColor: 'rgba(70, 130, 180, 0.5)',
        borderColor: 'rgba(70, 130, 180, 1)',
        borderWidth: 1,
        yAxisID: 'y',
        order: 2,
        barThickness: 40,
        maxBarThickness: 40,
        barPercentage: 0.9,
        categoryPercentage: 0.9,
      },
      {
        label: 'Taxa Tomador (%)',
        data: tickerData.map((item) => {
          console.log('Taxa para data', item.dataCarga, ':', item.taxaTomador);
          return item.taxaTomador;
        }),
        type: 'line',
        borderColor: '#FF6384',
        pointBackgroundColor: '#FF6384',
        borderWidth: 2,
        tension: 0.4,
        yAxisID: 'y1',
        order: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        fill: false,
        cubicInterpolationMode: 'monotone',
      },
    ];
  }, [tickerData]);


  useEffect(() => {
    fetchAlugueisData();
  }, [fetchAlugueisData]);

  // Otimize o filtro de dados usando useCallback
  const filterData = useCallback(() => {
    if (selectedDateCarga) {
      setFilteredData(alugueisData.filter(item => item.dataCarga === selectedDateCarga));
    }
  }, [selectedDateCarga, alugueisData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Otimização: Memoize o objeto de dados completo do gráfico
  const chartData = useMemo(() => ({
    labels: chartLabels,
    datasets: chartDatasets,
  }), [chartLabels, chartDatasets]);

  // Otimização: Memoize as opções do gráfico com configurações de performance
  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    responsiveAnimationDuration: 0,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: axisColor,
          font: {
            size: 14,
            family: "'Roboto', sans-serif",
          },
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        animation: {
          duration: 100
        },
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return label === 'Taxa Tomador (%)'
              ? `${label}: ${value.toFixed(2)}%`
              : `${label}: ${value.toLocaleString()}`;
          }
        }
      },
      annotation: {
        annotations: tickerData.map((item, index) => ({
          type: 'label',
          xValue: index,
          yValue: item.qtdeAtivo,
          backgroundColor: item.varPercQtdeAtivo >= 0 ? 'rgba(75, 192, 192, 0.8)' : 'rgba(255, 99, 132, 0.8)',
          content: `${item.varPercQtdeAtivo.toFixed(1)}%`,
          font: {
            size: 11,
            weight: 'bold'
          },
          color: 'white',
          yAdjust: -10, // Ajusta a posição vertical para ficar acima da barra
          borderRadius: 4,
          padding: {
            top: 2,
            bottom: 2,
            left: 4,
            right: 4
          }
        }))
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: axisColor,
          maxRotation: 45,
          minRotation: 45,
          maxTicksLimit: 10,
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: formatYAxis,
          color: axisColor,
          maxTicksLimit: 8,
        },
        grid: {
          color: gridColor,
          drawBorder: false,
        },
        // Adiciona um pequeno espaço extra no topo para as anotações
        afterDataLimits: (scale) => {
          scale.max = scale.max * 1.1;
        }
      },
      y1: {
        type: 'linear',
        position: 'right',
        ticks: {
          callback: (value) => `${value}%`,
          color: axisColor,
          maxTicksLimit: 8,
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        hitRadius: 10,
      },
    },
    devicePixelRatio: 2,
    layout: {
      padding: {
        top: 20 // Adiciona padding no topo para as anotações
      }
    }
  }), [axisColor, gridColor, tickerData]);


  const columns = useMemo(
    () => [
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Quantidade de Ativos',
        accessor: 'qtdeAtivo',
        sortType: (a, b) => a.original.qtdeAtivo - b.original.qtdeAtivo,
        Cell: ({ value }) => value.toLocaleString('pt-BR'),
      },
      {
        Header: 'Taxa Tomador (%)',
        accessor: 'taxaTomador',
        sortType: (a, b) => a.original.taxaTomador - b.original.taxaTomador,
        Cell: ({ value }) => (value ? value.toFixed(2) + '%' : 'N/A'), // Ajusta para valores nulos.
      }
    ],
    []
  );


  useEffect(() => {
    fetchAlugueisData();
  }, []);
  
  useEffect(() => {
    if (uniqueDates.length > 0) {
      // Garante que a data mais recente seja selecionada após o carregamento
      const latestDate = uniqueDates[0];
      setSelectedDateCarga(latestDate);
    }
  }, [uniqueDates]);


  const tableInstance = useTable({ columns, data: filteredData }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold" mb={6}>Andamento do Aluguel de Ações</Text>

      <HStack spacing={4} mb={4}>
        <Select value={selectedTicker} onChange={(e) => setSelectedTicker(e.target.value)}>
          {tickers.map((ticker) => (
            <option key={ticker} value={ticker}>
              {ticker}
            </option>
          ))}
        </Select>
        <Select 
          value={selectedDateCarga}
          onChange={(e) => {
            console.log('Selecionando nova data:', e.target.value);
            setSelectedDateCarga(e.target.value);
          }}
        >
          {uniqueDates.map((date) => (
            <option key={date} value={date}>
      {new Date(date + 'T00:00:00').toLocaleDateString('pt-BR')}
      </option>
          ))}
        </Select>
      </HStack>

      {!loading && (
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
          <Box bg={cardBg} p={4} rounded="lg" shadow="sm">
            <Text color="gray.600" fontSize="sm">Taxa Atual</Text>
            <Flex align="center" mt={1}>
              <Text fontSize="xl" fontWeight="bold">
                {stats.lastFee}%
              </Text>
              {parseFloat(stats.lastFee) > parseFloat(stats.avgLendingFee) ?
                <TrendingUp className="ml-2 text-green-500" /> :
                <TrendingDown className="ml-2 text-red-500" />
              }
            </Flex>
          </Box>

          <Box bg={cardBg} p={4} rounded="lg" shadow="sm">
            <Text color="gray.600" fontSize="sm">Taxa Média</Text>
            <Text fontSize="xl" fontWeight="bold" mt={1}>
              {stats.avgLendingFee}%
            </Text>
          </Box>

          <Box bg={cardBg} p={4} rounded="lg" shadow="sm">
            <Text color="gray.600" fontSize="sm">Quantidade Atual</Text>
            <Flex align="center" mt={1}>
              <Text fontSize="xl" fontWeight="bold">
                {parseInt(stats.lastQuantity).toLocaleString()}
              </Text>
              {parseInt(stats.lastQuantity) > parseInt(stats.avgQuantity) ?
                <TrendingUp className="ml-2 text-green-500" /> :
                <TrendingDown className="ml-2 text-red-500" />
              }
            </Flex>
          </Box>

          <Box bg={cardBg} p={4} rounded="lg" shadow="sm">
            <Text color="gray.600" fontSize="sm">Quantidade Média</Text>
            <Text fontSize="xl" fontWeight="bold" mt={1}>
              {parseInt(stats.avgQuantity).toLocaleString()}
            </Text>
          </Box>
        </Grid>
      )}


      {loading ? (
        <Skeleton height="400px" />
      ) : (
        <Box height="400px">
          {/* Debug: mostrar dados atuais */}
          <div style={{ display: 'none' }}>
            <pre>
              Selected Ticker: {selectedTicker}
              Data Points: {tickerData.length}
              Latest Date: {tickerData[tickerData.length - 1]?.dataCarga}
            </pre>
          </div>
          <Bar data={chartData} options={chartOptions} />
        </Box>
      )}

      <Box mt={8}>

      <TableContainer mt={8}>
        <Table {...getTableProps()} borderWidth="1px" borderRadius="md" bg={tableBg} borderColor={gridColor}>
          <Thead>
            {headerGroups.map((headerGroup, i) => {
              const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
              return (
                <Tr key={key || i} {...headerGroupProps} bg={tableBg}>
                  {headerGroup.headers.map((column, j) => {
                    const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <Th
                        key={columnKey || j}
                        {...columnProps}
                        color={tableTextColor}
                        borderBottom="2px solid"
                        borderColor={gridColor}
                      >
                        <Flex align="center">
                          {column.render('Header')}
                          {column.isSorted && (
                            <Icon
                              as={column.isSortedDesc ? ChevronDownIcon : ChevronUpIcon}
                              ml={2}
                              boxSize={4}
                              color={column.isSortedDesc ? 'red.500' : 'green.500'}
                            />
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const { key: rowKey, ...rowProps } = row.getRowProps();
              return (
                <Tr key={rowKey || i} {...rowProps} _hover={{ bg: tableHoverColor }}>
                  {row.cells.map((cell, j) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <Td key={cellKey || j} {...cellProps} color={tableTextColor}>
                        {cell.column.id === 'qtdeAtivo' ? (
                          <Flex align="center" gap={2}>
                            {cell.value.toLocaleString('pt-BR')}
                            <Text color={row.original.varPercQtdeAtivo >= 0 ? 'green.500' : 'red.500'} fontSize="sm">
                              ({row.original.varPercQtdeAtivo >= 0 ? '+' : ''}{row.original.varPercQtdeAtivo?.toFixed(2)}%
                              <Icon 
                                as={row.original.varPercQtdeAtivo >= 0 ? TrendingUp : TrendingDown}
                                boxSize={4}
                                ml={1}
                                display="inline"
                              />)
                            </Text>
                          </Flex>
                        ) : cell.column.id === 'taxaTomador' ? (
                          <Flex align="center" gap={2}>
                            {cell.value?.toFixed(2)}%
                            <Text color={row.original.varPercTaxaTomador >= 0 ? 'green.500' : 'red.500'} fontSize="sm">
                              ({row.original.varPercTaxaTomador >= 0 ? '+' : ''}{row.original.varPercTaxaTomador?.toFixed(2)}%
                              <Icon 
                                as={row.original.varPercTaxaTomador >= 0 ? TrendingUp : TrendingDown}
                                boxSize={4}
                                ml={1}
                                display="inline"
                              />)
                            </Text>
                          </Flex>
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      </Box>

    </Box>
  );
};

export default AluguelChart;