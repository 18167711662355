import ReactGA from "react-ga4";

// Inicializa o Google Analytics
export const initializeAnalytics = () => {
    ReactGA.initialize("G-7XZFHEZZTF"); // Substitua pelo seu Measurement ID
};

// Rastreia visualizações de página, incluindo o hostname
export const trackPageView = () => {
    const page = window.location.pathname + window.location.search;
    const hostname = window.location.hostname;

    ReactGA.send({
        hitType: "pageview",
        page, // Caminho da página
        hostname, // Subdomínio (ex: fatcat.app.br ou go.fatcat.app.br)
    });
};

// Rastreia eventos personalizados
export const trackEvent = (category, action, label, value) => {
    ReactGA.event({
        category, // Categoria do evento
        action,   // Ação realizada
        label,    // Descrição ou rótulo (opcional)
        value,    // Valor associado ao evento (opcional)
    });
};

// Rastreia o subdomínio como uma dimensão personalizada
export const trackSubdomain = () => {
    const hostname = window.location.hostname;

    ReactGA.set({
        customDimension1: hostname, // Crie a dimensão no GA e use-a aqui
    });
};
