import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Card,
    CardHeader,
    CardBody,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Icon,
    useColorModeValue,
    Skeleton, Circle,
    Tooltip as ChakraTooltip
} from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Activity, TrendingUp, TrendingDown, Trophy, Check, AlertCircle } from 'lucide-react';
import axios from 'axios';

const SqueezeAnalytics = ({ setSelectTicker }) => {
    const [squeezeData, setSqueezeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const bgCard = useColorModeValue('white', 'gray.700');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.fatcat.app.br/90Squeeze');
                setSqueezeData(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleStockSelect = (symbol) => {
        setSelectTicker(symbol);
    };

    const AlignmentIndicator = ({ ali150, ali50 }) => (
        <ChakraTooltip
            label={`SMA150: ${ali150 ? "Alinhado" : "Não Alinhado"} | SMA50: ${ali50 ? "Alinhado" : "Não Alinhado"}`}
            placement="top"
        >
            <svg width="16" height="16" viewBox="0 0 10 10">
                <circle
                    cx="5"
                    cy="5"
                    r="4"
                    fill="none"
                    stroke="#E2E8F0"
                    strokeWidth="0.5"
                />
                {/* Left half - SMA150 */}
                <path
                    d="M5,5 A4,4 0 0,1 5,1 A4,4 0 0,1 5,9 Z"
                    fill={ali150 ? "#48BB78" : "#A0AEC0"}
                    opacity="0.8"
                />
                {/* Right half - SMA50 */}
                <path
                    d="M5,1 A4,4 0 0,1 5,9 A4,4 0 0,1 5,1 Z"
                    fill={ali50 ? "#38A169" : "#A0AEC0"}
                    opacity="0.8"
                />
            </svg>
        </ChakraTooltip>
    );

    const MetricCard = ({ icon: Icon, label, value, subValue, color, bgColor = "white" }) => (
        <Card bg={bgColor} boxShadow="lg" borderRadius="xl">
            <CardBody>
                <Stat>
                    <Flex align="center" mb={3}>
                        <Box as={Icon} w={6} h={6} mr={3} color={color} />
                        <StatLabel fontSize="lg">{label}</StatLabel>
                    </Flex>
                    <StatNumber fontSize="2xl" color={color}>{value}</StatNumber>
                    <StatHelpText fontSize="sm" color="gray.500">{subValue}</StatHelpText>
                </Stat>
            </CardBody>
        </Card>
    );

    const CustomBarLabel = ({ x, y, width, squeeze, ali150, ali50 }) => {
        const baseY = y - 25;
        const centerX = x + width / 2;

        return (
            <g>
                {/* Squeeze Indicator */}
                {squeeze === 1 && (
                    <path
                        transform={`translate(${centerX - 10},${baseY})`}
                        d="M22 12h-4l-3 9L9 3l-3 9H2"
                        stroke="#805AD5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                    />
                )}

                {/* Split Circle Alignment Indicator */}
                {(ali150 || ali50) && (
                    <g transform={`translate(${centerX - 5},${baseY - 15})`}>
                        {/* Left half - SMA150 */}
                        <path
                            d="M5,5 A5,5 0 0,1 5,0 A5,5 0 0,1 5,10 Z"
                            fill={ali150 ? "#48BB78" : "#A0AEC0"}
                            opacity="0.8"
                        >
                            <title>SMA150 {ali150 ? "Alinhado" : "Não Alinhado"}</title>
                        </path>
                        {/* Right half - SMA50 */}
                        <path
                            d="M5,0 A5,5 0 0,1 5,10 A5,5 0 0,1 5,0 Z"
                            fill={ali50 ? "#38A169" : "#A0AEC0"}
                            opacity="0.8"
                        >
                            <title>SMA50 {ali50 ? "Alinhado" : "Não Alinhado"}</title>
                        </path>
                    </g>
                )}
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <Box bg="gray.800" p={3} borderRadius="md" boxShadow="lg">
                    <Text fontWeight="bold" color="white">{data.ticker}</Text>
                    <Text color="white">Variação: {data.variacao_percentual}%</Text>
                    <Text color="white">BB/KC Ratio: {data.bb_kc_ratio}</Text>
                    <Text color="white">Squeeze: {data.squeeze ? "Sim" : "Não"}</Text>
                    <Flex mt={2} gap={2}>
                        <Badge colorScheme={data.ali150 ? "green" : "gray"}>
                            SMA150 {data.ali150 ? "✓" : "✗"}
                        </Badge>
                        <Badge colorScheme={data.ali50 ? "green" : "gray"}>
                            SMA50 {data.ali50 ? "✓" : "✗"}
                        </Badge>
                    </Flex>
                </Box>
            );
        }
        return null;
    };

    // Métricas calculadas
    const totalAtivos = squeezeData.length;
    const ativosEmSqueeze = squeezeData.filter(item => item.squeeze === 1).length;
    const mediaVariacao = squeezeData.reduce((acc, item) => acc + parseFloat(item.variacao_percentual), 0) / totalAtivos;
    const topPerformer = squeezeData.length > 0 ?
        [...squeezeData].sort((a, b) => parseFloat(b.variacao_percentual) - parseFloat(a.variacao_percentual))[0] : null;

    if (loading) {
        return <Box p={6}><Skeleton height="500px" /></Box>;
    }

    return (
        <Box p={6} maxW="7xl" mx="auto">
            <Heading size="lg" mb={6}>Análise de Squeeze</Heading>

            {/* Métricas Cards */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={6}>
                <MetricCard
                    icon={Activity}
                    label="Ativos em Squeeze"
                    value={`${ativosEmSqueeze}/${totalAtivos}`}
                    subValue={`${((ativosEmSqueeze / totalAtivos) * 100).toFixed(1)}% do total`}
                    color="purple.500"
                    bgColor={bgCard}
                />
                <MetricCard
                    icon={mediaVariacao >= 0 ? TrendingUp : TrendingDown}
                    label="Variação Média"
                    value={`${mediaVariacao.toFixed(2)}%`}
                    subValue="Média dos ativos analisados"
                    color={mediaVariacao >= 0 ? "green.500" : "red.500"}
                    bgColor={bgCard}
                />
                {topPerformer && (
                    <MetricCard
                        icon={Trophy}
                        label="Melhor Performance"
                        value={topPerformer.ticker}
                        subValue={`+${parseFloat(topPerformer.variacao_percentual).toFixed(2)}%`}
                        color="green.500"
                        bgColor={bgCard}
                    />
                )}
            </SimpleGrid>

            {/* Gráfico */}
            <Card bg={bgCard} boxShadow="md" mb={6}>
                <CardHeader>
                    <Heading size="md">Variação Percentual dos Ativos</Heading>
                </CardHeader>
                <CardBody>
                    <Box h="400px">
                        <ResponsiveContainer>
                            <BarChart
                                data={squeezeData}
                                margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                                <XAxis dataKey="ticker" tick={{ fill: '#D6BCFC' }} fontSize={12} fontWeight={500} />
                                <YAxis tick={{ fill: '#D6BCFC' }} fontSize={12} />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Bar
                                    dataKey="variacao_percentual"
                                    name="Variação %"
                                    onClick={(data) => handleStockSelect(data.ticker)}
                                    cursor="pointer"
                                    label={(props) => (
                                        <CustomBarLabel
                                            {...props}
                                            squeeze={squeezeData[props.index].squeeze}
                                            ali150={squeezeData[props.index].ali150}
                                            ali50={squeezeData[props.index].ali50}
                                        />
                                    )}
                                >
                                    {squeezeData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.squeeze === 1 ? '#805AD5' :
                                                (parseFloat(entry.variacao_percentual) >= 0 ? '#48BB78' : '#F56565')}
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </CardBody>
            </Card>

            {/* Tabela */}
            <Card bg={bgCard} boxShadow="md">
                <CardHeader>
                    <Heading size="md">Detalhamento dos Ativos</Heading>
                </CardHeader>
                <CardBody>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>SO</Th>
                                    <Th>Ativo</Th>
                                    <Th>Squeeze</Th>
                                    <Th>Alinhamento</Th>
                                    <Th>Preço</Th>
                                    <Th isNumeric>Variação %</Th>
                                    <Th isNumeric>Squeeze Ratio</Th>
                                    <Th isNumeric>Rank</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {squeezeData.map((item) => (
                                    <Tr key={item.ticker} onClick={() => handleStockSelect(item.ticker)} style={{ cursor: 'pointer' }}>
                                        <Td>
                                        <Circle
                                            size="8px"
                                            bg={item.has_options === 1 ? "blue.400" : "gray.500"}
                                        />
                                        </Td>
                                        <Td fontWeight="medium">{item.ticker}</Td>
                                        <Td>
                                            <Flex
                                                as={Badge}
                                                alignItems="center"
                                                px={2}
                                                py={1}
                                                borderRadius="full"
                                                bg={item.squeeze === 1 ? 'purple.100' : 'gray.100'}
                                                color={item.squeeze === 1 ? 'purple.800' : 'gray.800'}
                                                fontSize="sm"
                                            >
                                                {item.squeeze === 1 ? (
                                                    <Check size={16} style={{ marginRight: '4px' }} />
                                                ) : (
                                                    <AlertCircle size={16} style={{ marginRight: '4px' }} />
                                                )}
                                                {item.squeeze === 1 ? 'SIM' : 'NÃO'}
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <AlignmentIndicator ali150={item.ali150} ali50={item.ali50} />
                                        </Td>
                                        <Td>R$ {parseFloat(item.preco_atual).toFixed(2)}</Td>
                                        <Td
                                            isNumeric
                                            color={parseFloat(item.variacao_percentual) >= 0 ? 'green.500' : 'red.500'}
                                        >
                                            {parseFloat(item.variacao_percentual).toFixed(2)}%
                                        </Td>
                                        <Td isNumeric>{item.bb_kc_ratio}</Td>
                                        <Td isNumeric>{item.rank}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        </Box>
    );
};

export default SqueezeAnalytics;